import orchid from "../assets/sponsors/orchid.jpg"
import niglaRC from "../assets/sponsors/niglaRC.jpg"
import niglaHealth from "../assets/sponsors/niglaHealth.jpg"
import logoNE from "../assets/sponsors/logoNE.jpg"
// import img from "../assets/bgmi.jpg"

export const Data = [

    {
        id: 1,
        Title: "  Orchid",
        content: "",
        backgroundImage: orchid
    },
    {
        id: 2,
        Title: "Nigla Health Care",
        content: "",
        backgroundImage: niglaHealth
    },
    {   id:3,
        Title: "Nigla Rehabilitation Centre",
        content: "",
        backgroundImage: niglaRC
    },
    {   id:4,
        Title: "NE Departmental Store",
        content: "",
        backgroundImage: logoNE
    },


];