/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 kyuko.gltf 
Author: Thimeo (https://sketchfab.com/Thimeo)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/kyouko-kirigiri-figure-from-danganronpa-84687eb3bbe74aa891b3327e42f06ab6
Title: Kyouko Kirigiri figure from Danganronpa
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';

export default function Model(props) {

  const group = useRef();


  useFrame((state, delta)=>{
    group.current.rotation.y +=delta; 
 })


  const { nodes, materials } = useGLTF('/kyuko.gltf')
  return (
    <group ref={group} scale={4.7} {...props} dispose={null}>
      <group position={[-0.651, -0.409, 0.821]} rotation={[-1.859, 0.053, 0.254]}>
        <mesh geometry={nodes.Object_2.geometry} material={materials.model_Material_u1_v1} />
        <mesh geometry={nodes.Object_3.geometry} material={materials.model_Material_u1_v1} />
      </group>
    </group>
  )
}

useGLTF.preload('/kyuko.gltf')
