import club1 from '../assets/clubs/club1.jpg'
import club2 from '../assets/clubs/club2.jpg'
import club3 from '../assets/clubs/club3.jpg'
import club4 from '../assets/clubs/club4.jpg'
import club5 from '../assets/clubs/club5.jpg'
import club6 from '../assets/clubs/club6.jpg'
import club8  from '../assets/clubs/club8.jpg'
import club9 from '../assets/clubs/club9.jpg'
import club10 from '../assets/clubs/club10.jpg'
import club11 from "../assets/clubs/VideographyClub.png"

export const  Data = [
{
    Title: "Drama Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club1,
    url:"/drama/events"
},

{
    Title: "Elocution Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club2,
    url:"/elocution/events"
},

{
    Title: "Hobby Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club3,
    url:"/hobby/events"
},

{
    Title: "Music Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club4,
    url:"/music/events"
},

{
    Title: "Photography Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club5,
    url:"/photography/events"
},

{
    Title: "Writer's Forum",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club8,
    url:"/writer/events"
},

{
    Title: "Dance Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club9,
    url:"/dance/events"
},
{
    Title: "Quiz Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club6,
    url:"/quiz/events"
},

{
    Title: "Game Zone",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club10,
    url:"/game/events"
},
{
    Title: "Videography Club",
    content: "nerost drama club presr akj fk amsdf  ajksdfn ajkdsnf aksjdf  akjsd",
    backgroundImage: club11,
    url:"/videography/events"
},



];